.codeEditorContainer {
    max-height: auto;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    overflow: auto !important;
}

.codeEditor {
    font-family: 'Courier';
    font-size: 14px;
    counter-reset: line;
    background: #f8fafc;
    min-height: 100%;
}

.codeHighlighted pre {
    padding: 16px 0 16px 34px !important;
    margin-left: 8px !important;
}

.codeEditorTextArea {
    outline: none;
    z-index: 1;
}

.codeTextAreaHighlighted {
    padding: 16px 0 16px 42px !important;
}

.codeEditorLineNum {
    padding-left: 42;
}

.codeEditorLineNum:before {
    color: #cbd5e1; /*  lightNeutral[400]; */
    position: absolute;
    left: 0;
    width: 40;
    text-align: right;
    user-select: none;
    counter-increment: line;
    content: counter(line);
    padding-right: 1;
}
